@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
body {
    font-family: 'Poppins', sans-serif;
    color: #182c61;
}

#title {
    margin-top: 150px;
}

.h1, h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
}

#join-dialog, #subscriber-dialog {
    width: 100%;
    padding: 50px 100px;
    border: 1px solid #182c61;
    height: 400px;
}

.react-tabs__tab-list {
    margin: 0 !important;
}
#session #header_img {
    margin-top: 0px;
    height: 100%;
    width: 150px;
}

.OT_publisher.inactiveUser {
    display: none;
}

.OT_publisher {
    display: block;
}

.bounds {
    width: 100% !important;
}

.form-control {
    border: 1px solid #27ae60;
    border-radius: unset;
}

#join-session, #subscriber-session {
    background: #1e2d68;
    padding: 10px;
    border-radius: unset;
    border: unset;
    width: 150px;
    color: #fff;
}

#join-title {
    color: #1e2d68;
} 

#create-join-tabs {
    top: 150px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-tabs__tab {
    width: 50%;
    padding: 15px 0 !important;
    font-size: 18px;
}

.react-tabs__tab--selected {
    color: #fff !important;
    background-color: #182c61 !important;
    border-color: #182c61 !important;
    border-radius: unset !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
#messageInput {
    width: 100% !important;
    height: 52px !important;
}

/* .attendese-count {
    background-color: green;
    border-radius: 100%;
    padding: 5px;
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 12px;
} */

#support {
    top: 40px;
    display: block;
    overflow: hidden;
    margin-bottom: 30px;
}

@media only screen and (max-width: 700px) {
    #title {
        margin-top: 20px;
    }
    
    #join-dialog, #subscriber-dialog {
        width: 100%;
        padding: 20px 10px;
        border: 1px solid #182c61;
        height: 330px;
    }

    .mainLogo {
        width: 100%;
        margin: 30px 0;
        padding: 0 80px;
    }

    #navSessionInfo {
        position: absolute;
        width: 100%;
    }

    .buttonsContent {
        margin: 0 auto;
        min-width: 100%;
        position: fixed;
        bottom: 10px;
        background: #ffffff1c;
        color: #2e2e2e;
    }

    #header {
        min-width: 100%;
    }

    .toolbar {
        min-height: 42px !important;
    }

    #titleContent {
        padding: 14px !important;
        height: 42px !important;
    }

    .buttonsContent {
        height: 42px !important;
        background: #00000073;
        text-align: unset !important;
        bottom: 0;
        padding: 0px;
        color: #ffffff;
        position: fixed;
        left: 0;
    }

    #statusIcons {
        top: 0;
        right: 0;
    }

    #titleContent, #navChatButton {
        display: inline-flex !important;
    }

    #chatComponent {
        background-color: #b8b8b8;
        position: absolute;
        z-index: 99999;
        margin: auto;
        height: 100% !important;
        width: 100% !important;
        border-radius: 20px;
        right: 0 !important;
        bottom: 42px !important;
    }

    #chatContainer {
        position: absolute;
        z-index: 0;
        width: 100% !important;
        height: 80% !important;
        right: 0 !important;
        bottom: 42px !important;
    }

    #create-join-tabs {
        top: 0;
    }
} 


@media (min-width: 600px) {
    .toolbar {
        min-height: 48px !important;
    }
}
